'use client';

import { useCallback, useState } from 'react';

import { useTranslation } from '@cms/i18n/client';
import { FeaturedCarouselLayout } from '@common/clients/api';
import { DefaultServerSideProps } from '@common/types/DefaultProps';
import { ItemsPerPage } from '@common/types/ItemsPerPage';
import { ResponsiveToggle } from '@web/atoms/ResponsiveToggle';
import { fetchHomepageNews } from '@web/handlers/fetchHomepageNews';
import { MediaQuery } from '@web/hooks/useResponsive/useResponsive';
import { Ad, AdPlacement } from '@web/molecules/Ad';
import { FeaturedCarrousel } from '@web/molecules/FeaturedCarrousel';
import { NotificationBarHolder } from '@web/molecules/NotificationBar';
import { MetaHead } from '@web/organisms/MetaHead';
import { Sidebar } from '@web/organisms/Sidebar';
import { WidgetWithFetch as RecentNewsWrapper } from '@web/organisms/SideWidgets/RecentNews';
import styles from '@web/templates/HomePage/HomePage.module.scss';
import { HomeNewsList } from '@web/templates/HomePage/organisms/HomeNewsList/HomeNewsList';

import { Props, State } from './HomePage';

export const TIMELINE_AD_INDEX = 5;

export const ViHomePage = ({
    contextData,
    state: initialState,
    featuredItems,
    curatedMatches,
    headlinesDossier,
    oddsDossier,
}: Props & DefaultServerSideProps) => {
    const platform = contextData?.platform;
    const { sidebarProps } = contextData;
    const [state, setState] = useState<State>(initialState);
    const { currentPage, news, hasNextPage } = state;
    const __meta = useTranslation('meta').t;

    const onReadMoreClick = useCallback(async () => {
        const nextPage = currentPage + 1;
        const newsResponse = await fetchHomepageNews(contextData, nextPage, true, ItemsPerPage.HUGE);

        setState((prevState) => ({
            ...prevState,
            currentPage: nextPage,
            news: [...prevState.news, ...(newsResponse?.data || [])],
            hasNextPage: newsResponse?.pagination?.hasNextPage || false,
        }));
    }, [currentPage, contextData]);

    const recentNewsData = sidebarProps && 'recent-news' in sidebarProps ? sidebarProps['recent-news'] : [];
    const fiveRecentArticles = recentNewsData ? recentNewsData.filter((_, index) => index < 5) : [];

    return (
        <div className={styles.HomePage}>
            <MetaHead
                title={__meta(`${platform.id}-homepage.title`)}
                description={__meta(`${platform.id}-homepage.description`)}
            />
            <Ad placement={AdPlacement.HOME_BEFORE_CONTENT} />
            <Sidebar adIndex={TIMELINE_AD_INDEX} direction="right" />
            <div className="main-content">
                <FeaturedCarrousel items={featuredItems} layout={FeaturedCarouselLayout.TWIN_MAINS} />
                <Ad placement={AdPlacement.HOME_AFTER_CAROUSEL} />
                <ResponsiveToggle
                    breakPoint={MediaQuery.m}
                    mobile={
                        <>
                            <RecentNewsWrapper dataList={fiveRecentArticles} />
                            <Ad placement={AdPlacement.HOME_BEFORE_NEWS_LIST} />
                        </>
                    }
                />
                <HomeNewsList
                    news={news}
                    curatedMatches={curatedMatches}
                    platform={platform.id}
                    hasNextPage={hasNextPage}
                    onReadMoreClick={onReadMoreClick}
                    headlinesDossier={headlinesDossier}
                    oddsDossier={oddsDossier}
                    skipDivider={true}
                />
            </div>
            <NotificationBarHolder />
        </div>
    );
};
